
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import Company from '@/models/Company'
import Profile from '@/models/Profile'
import User from '@/models/User'
import SelectOption from '@/models/interface/SelectOption'
import MediaPlan from '@/models/MediaPlan'
import IconAction from '@/components/IconAction/IconAction.vue'
import Invoice from '@/models/Invoice'
import GeoTargettingModule from '@/models/GeoTargettingModule'
import Order from '@/models/Order'
import LineItem from '@/models/LineItem'

@Component({
  components: {
    SelectPicker,
    IconAction,
  },
})
export default class SearchInput extends Vue {
  private debounce: number = 500

  private timeout: any = null

  public ready = false

  public inclusive: boolean = false

  @Prop({ default: false })
  public rules!: boolean

  @Prop({ default: false })
  public internal_search!: boolean

  @Prop({ default: () => {} })
  public extras!: any

  @Prop({ default: 'tag' })
  public mode!: string

  @Prop({ default: false })
  public show_helper!: boolean

  @Prop({ default: [] })
  public value!: string[]

  @Prop({ default: '' })
  public rowClass!: any

  @Prop({
    default: () => ({
      company_fields: [],
      custom_fields: [],
    }),
  })
  public settings!: any

  @Prop()
  public updatesPage!: any

  @Watch('inclusive')
  public onChangeFilterRule(val: any) {
    this.$emit('update:filterMode', val ? 'inclusive' : 'exclusive')
  }

  public get local_show_helper(): boolean {
    return this.show_helper
  }

  public set local_show_helper(value: boolean) {
    this.$emit('update:show_helper', value)
  }

  public local_value: string[] = []

  public busy: boolean = false

  public update: boolean = true

  public custom_options: object[] = []

  public custom_fields_filtered: object[] = []

  public local_options: object[] = []

  protected negate: boolean = false

  protected negate_prefix = '!'

  protected negate_suffix = ''

  public get search_options() {
    return [
      ...this.custom_options,
      // ...this.local_options,
      ...this.custom_fields_filtered,
      ...this.company_options,
      ...this.user_options,
      ...this.profile_options,
      ...this.media_plan_options,
      ...this.invoice_options,
      ...this.geo_location_options,
      ...this.order_options,
      ...this.line_item_options,
    ]
  }

  public get dynamicFields() {
    let ret: any = []

    if (this.settings.company_fields) ret = [...ret, ...this.settings.company_fields]

    if (this.settings.user_fields) ret = [...ret, ...this.settings.user_fields]

    if (this.settings.media_plan_fields) ret = [...ret, ...this.settings.media_plan_fields]

    if (this.settings.geo_location) ret = [...ret, ...this.settings.geo_location]

    if (this.settings.order_fields) ret = [...ret, ...this.settings.order_fields]

    if (this.settings.invoice_fields) ret = [...ret, ...this.settings.invoice_fields]

    if (this.settings.line_item_fields) ret = [...ret, ...this.settings.line_item_fields]

    return ret
  }

  public resetFields() {
    this.custom_options = []
    this.local_options = []
    this.company_options = []
    this.user_options = []
    this.profile_options = []
    this.media_plan_options = []
    this.invoice_options = []
    this.geo_location_options = []
    this.order_options = []
    this.line_item_options = []
  }

  public addCustomTag(tag: any) {
    if (this.search_options.some(o => o.value == tag.value)) return

    this.custom_options.push(tag)
  }

  public addTag(newTag: string) {
    // added this to prevent loss of data when adding tag
    let local_copy = JSON.parse(JSON.stringify(this.local_value || []))

    this.addCustomTag({
      name: newTag,
      value: newTag,
    })

    local_copy.push(newTag)

    this.local_value = local_copy
  }

  @Watch('local_value')
  public onChangeValue(val: any) {
    this.$emit('input', val)
    this.update = false
  }

  @Watch('value')
  public onChangeProp(val: any) {
    if (this.update) {
      this.local_value = val
    }
    this.update = true
  }

  public has(tag: SelectOption) {
    return this.local_value.some(o => o === tag.value)
  }

  public remove(tag: SelectOption) {
    this.local_value = this.local_value.filter(o => o !== tag.value)
  }

  public add(tag: any) {
    const value = this.local_value
    value.push(tag.value)
    this.local_value = value
  }

  public searchOptions(search = '*') {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.negate = false
    if (this.rules !== false) {
      if (search.indexOf('!') === 0) {
        this.negate = true
        // Remove the ! from the beginning of the search string
        search = search.substring(1)
        this.negate_prefix = '!'
        this.negate_suffix = ''
      } else if (search.indexOf('not_') === 0) {
        this.negate = true
        // Remove the not_ from the beginning of the search string
        search = search.substring(4)
        this.negate_prefix = 'not_'
        this.negate_suffix = ''
      } else if (search.includes('_not:')) {
        this.negate = true
        // Remove the not: from the beginning of the search string
        search = search.replace('_not:', ':')
        this.negate_prefix = ''
        this.negate_suffix = '_not'
      }
    }

    this.timeout = setTimeout(() => {
      let type = null

      if (
        this.settings.company_fields
        && this.settings.company_fields.some((o: any) => search.includes(`${o.name}:`))
      ) {
        type = 'company'
      } else if (
        this.settings.user_fields
        && this.settings.user_fields.some((o: any) => search.includes(`${o.name}:`))
      ) {
        type = 'user'
      } else if (
        this.settings.profile_fields
        && this.settings.profile_fields.some((o: any) => search.includes(`${o.name}:`))
      ) {
        type = 'profile'
      } else if (
        this.settings.media_plan_fields
        && this.settings.media_plan_fields.some((o: any) => search.includes(`${o.name}:`))
      ) {
        type = 'media_plan'
      } else if (
        this.settings.geo_location
        && this.settings.geo_location.some((o: any) => search.includes(`${o.name}:`))
      ) {
        type = 'geo_location'
      } else if (
        this.settings.order_fields
        && this.settings.order_fields.some((o: any) => search.includes(`${o.name}:`))
      ) {
        type = 'order'
      } else if (
        this.settings.invoice_fields
        && this.settings.invoice_fields.some((o: any) => search.includes(`${o.name}:`))
      ) {
        type = 'invoice'
      } else if (
        this.settings.line_item_fields
        && this.settings.line_item_fields.some((o: any) => search.includes(`${o.name}:`))
      ) {
        type = 'line_item'
      }

      if (!type || type == 'company') this.searchCompanyOptions(search)
      if (!type || type == 'user') this.searchUserOptions(search)
      if (!type || type == 'profile') this.searchProfileOptions(search)
      if (!type || type == 'media_plan') this.searchMediaPlanOptions(search)
      if (!type || type == 'invoice') this.searchInvoiceOptions(search)
      if (!type || type == 'geo_location') this.searchGeoLocationOptions(search)
      if (!type || type == 'order') this.searchOrderOptions(search)
      if (!type || type == 'line_item') this.searchLineItemOptions(search)

      this.filterCustomOptions(search)
    }, this.debounce)
  }

  public filterCustomOptions(search: string) {
    if (search === '*' || !search) {
      this.custom_fields_filtered = [...this.local_options]
      return
    }
    this.custom_fields_filtered = this.local_options.filter((o: any) =>
      o.name.toLowerCase().includes(search.toLowerCase()))
  }

  public company_options: SelectOption[] = []

  public searchCompanyOptions(search = '*', field = 'name') {
    if (!this.settings.company_fields) return

    this.busy = true

    this.settings.company_fields.forEach((field: any) => {
      search = search.replace(`${field.name}:`, '').replace(`${field.key}:`, '')
    })

    if (!search) search = '*'

    let query: any = {
      search: search.includes('*') ? search : `*${search}*`,
    }

    if (field === 'value') {
      query = {
        value: search,
      }
    }

    return Company.searchOptions(query).then(response => {
      const options: SelectOption[] = []
      response.forEach(o => {
        this.settings.company_fields.forEach((field: any) => {
          if (!field.type || field.type == o.type) {
            options.push(
              new SelectOption(
                `${this.negate ? this.negate_prefix : ''}${field.name}${
                  this.negate ? this.negate_suffix : ''
                }:${o.name}`,
                `${this.negate ? '!' : ''}${field.key}:${o.value}`,
              ),
            )
          }
        })
      })

      this.company_options = options
      this.busy = false
    })
  }

  public order_options: SelectOption[] = []

  public searchOrderOptions(search = '*', field = 'name') {
    if (!this.settings.order_fields) return

    this.busy = true

    this.settings.order_fields.forEach((field: any) => {
      search = search.replace(`${field.name}:`, '').replace(`${field.key}:`, '')
    })

    if (!search) search = '*'

    let query: any = {
      search: search.includes('*') ? search : `*${search}*`,
    }

    if (field === 'value') {
      query = {
        value: search,
      }
    }

    return Order.searchOptions(query).then(response => {
      const options: SelectOption[] = []
      response.forEach(o => {
        this.settings.order_fields.forEach((field: any) => {
          if (!field.type || field.type == o.type) {
            options.push(
              new SelectOption(
                `${this.negate ? this.negate_prefix : ''}${field.name}${
                  this.negate ? this.negate_suffix : ''
                }:${o.name}`,
                `${this.negate ? '!' : ''}${field.key}:${o.value}`,
              ),
            )
          }
        })
      })

      this.order_options = options
      this.busy = false
    })
  }

  public line_item_options: SelectOption[] = []

  public searchLineItemOptions(search = '*', field = 'name') {
    if (!this.settings.line_item_fields) return

    this.busy = true

    this.settings.line_item_fields.forEach((field: any) => {
      search = search.replace(`${field.name}:`, '').replace(`${field.key}:`, '')
    })

    if (!search) search = '*'

    let query: any = {
      search: search.includes('*') ? search : `*${search}*`,
    }

    if (field === 'value') {
      query = {
        value: search,
      }
    }

    return LineItem.searchOptions(query).then(response => {
      const options: SelectOption[] = []
      response.forEach(o => {
        this.settings.line_item_fields.forEach((field: any) => {
          if (!field.type || field.type == o.type) {
            options.push(
              new SelectOption(
                `${this.negate ? this.negate_prefix : ''}${field.name}${
                  this.negate ? this.negate_suffix : ''
                }:${o.name}`,
                `${this.negate ? '!' : ''}${field.key}:${o.value}`,
              ),
            )
          }
        })
      })

      this.line_item_options = options
      this.busy = false
    })
  }

  public media_plan_options: SelectOption[] = []

  public searchMediaPlanOptions(search = '*', field = 'name') {
    if (!this.settings.media_plan_fields) return

    this.busy = true

    this.settings.media_plan_fields.forEach((field: any) => {
      search = search.replace(`${field.name}:`, '').replace(`${field.key}:`, '')
    })

    if (!search) search = '*'

    let query: any = {
      search: search.includes('*') ? search : `*${search}*`,
    }

    if (field === 'value') {
      query = {
        value: search,
      }
    }

    return MediaPlan.searchOptions(query).then(response => {
      const options: SelectOption[] = []
      response.forEach(o => {
        this.settings.media_plan_fields.forEach((field: any) => {
          if (!field.type || field.type == o.type) {
            options.push(
              new SelectOption(
                `${this.negate ? this.negate_prefix : ''}${field.name}${
                  this.negate ? this.negate_suffix : ''
                }:${o.full_name || o.name}`,
                `${this.negate ? '!' : ''}${field.key}:${o.value}`,
              ),
            )
          }
        })
      })

      this.media_plan_options = options
      this.busy = false
    })
  }

  public invoice_options: SelectOption[] = []

  public searchInvoiceOptions(search = '*', field = 'name') {
    if (!this.settings.invoice_fields) return

    this.busy = true

    this.settings.invoice_fields.forEach((field: any) => {
      search = search.replace(`${field.name}:`, '').replace(`${field.key}:`, '')
    })

    if (!search) search = '*'

    let query: any = {
      search: search.includes('*') ? search : `*${search}*`,
    }

    if (field === 'value') {
      query = {
        value: search,
      }
    }

    return Invoice.searchOptions(query).then(response => {
      const options: SelectOption[] = []
      response.forEach(o => {
        this.settings.invoice_fields.forEach((field: any) => {
          if (!field.type || field.type == o.type) {
            options.push(
              new SelectOption(
                `${this.negate ? this.negate_prefix : ''}${field.name}${
                  this.negate ? this.negate_suffix : ''
                }:${o.full_name}`,
                `${this.negate ? '!' : ''}${field.key}:${o.value}`,
              ),
            )
          }
        })
      })

      this.media_plan_options = options
      this.busy = false
    })
  }

  public user_options: SelectOption[] = []

  public searchUserOptions(search = '*', field = 'name') {
    if (!this.settings.user_fields) return

    this.busy = true

    this.settings.user_fields.forEach((field: any) => {
      search = search.replace(`${field.name}:`, '').replace(`${field.key}:`, '')
    })

    if (!search) search = '*'

    let query: any = {
      search: search.includes('*') ? search : `*${search}*`,
    }

    if (field === 'value') {
      query = {
        value: search,
      }
    }

    return User.searchOptions(query).then(response => {
      const options: SelectOption[] = []
      response.forEach(o => {
        this.settings.user_fields.forEach((field: any) => {
          if (!field.type || field.type == o.type) {
            options.push(
              new SelectOption(
                `${this.negate ? this.negate_prefix : ''}${field.name}${
                  this.negate ? this.negate_suffix : ''
                }:${o.name}`,
                `${this.negate ? 'not_' : ''}${field.key}:${o.value}`,
              ),
            )
          }
        })
      })

      this.user_options = options
      this.busy = false
    })
  }

  public profile_options: SelectOption[] = []

  public searchProfileOptions(search = '*', field = 'name') {
    if (!this.settings.profile_fields) return

    this.busy = true

    this.settings.profile_fields.forEach((field: any) => {
      search = search.replace(`${field.name}:`, '').replace(`${field.key}:`, '')
    })

    if (!search) search = '*'

    let query: any = {
      search: search.includes('*') ? search : `*${search}*`,
    }

    if (field === 'value') {
      query = {
        value: search,
      }
    }

    return Profile.searchOptions(query).then(response => {
      const options: SelectOption[] = []
      response.forEach(o => {
        this.settings.profile_fields.forEach((field: any) => {
          if (!field.type || field.type == o.type) {
            options.push(
              new SelectOption(
                `${this.negate ? this.negate_prefix : ''}${field.name}${
                  this.negate ? this.negate_suffix : ''
                }:${o.name}`,
                `${this.negate ? 'not_' : ''}${field.key}:${o.value}`,
              ),
            )
          }
        })
      })

      this.profile_options = options
      this.busy = false
    })
  }

  public geo_location_options: SelectOption[] = []

  public searchGeoLocationOptions(search = '*', field = 'name') {
    if (!this.settings.geo_location) return

    this.busy = true
    let type = ''
    this.settings.geo_location.forEach((field: any) => {
      if (search.includes(`${field.name}:`)) {
        type = field.type
      }
      search = search.replace(`${field.name}:`, '')
    })

    if (!search) search = '*'

    let query: any = {
      search: search.includes('*') ? search : `*${search}*`,
    }

    if (field === 'value') {
      query = {
        value: search,
      }
    }

    if (type) query.type = type

    return GeoTargettingModule.searchOptions(query).then(response => {
      const options: SelectOption[] = []
      response.forEach(o => {
        this.settings.geo_location.forEach((field: any) => {
          if (!field.type || field.type == o.type) {
            options.push(
              new SelectOption(
                `${this.negate ? this.negate_prefix : ''}${field.name}${
                  this.negate ? this.negate_suffix : ''
                }:${this.negate ? 'not_' : ''}${o.full_name}`,
                o.value,
              ),
            )
          }
        })
      })

      this.geo_location_options = options
      this.busy = false
    })
  }

  public mounted() {
    this.searchOptions()
  }

  public created() {
    if (this.mode != 'tag') {
      this.local_value = []
      return
    }
    if (this.settings.custom_fields) {
      this.local_options = [...this.local_options, ...this.settings.custom_fields]
    }

    // Locate initial values
    if (this.value.length > 0) {
      let promises: Promise[] = []
      this.value.forEach((search: any) => {
        let type = null

        if (
          this.settings.company_fields
          && this.settings.company_fields.some((o: any) => search.includes(`${o.key}:`))
        ) {
          type = 'company'
        } else if (
          this.settings.user_fields
          && this.settings.user_fields.some((o: any) => search.includes(`${o.key}:`))
        ) {
          type = 'user'
        } else if (
          this.settings.profile_fields
          && this.settings.profile_fields.some((o: any) => search.includes(`${o.key}:`))
        ) {
          type = 'profile'
        } else if (
          this.settings.media_plan_fields
          && this.settings.media_plan_fields.some((o: any) => search.includes(`${o.key}:`))
        ) {
          type = 'media_plan'
        } else if (
          this.settings.geo_location
          && this.settings.geo_location.some((o: any) => search.includes(`${o.key}:`))
        ) {
          type = 'geo_location'
        } else if (
          this.settings.order_fields
          && this.settings.order_fields.some((o: any) => search.includes(`${o.key}:`))
        ) {
          type = 'order'
        } else if (
          this.settings.invoice_fields
          && this.settings.invoice_fields.some((o: any) => search.includes(`${o.key}:`))
        ) {
          type = 'invoice'
        } else if (
          this.settings.line_item_fields
          && this.settings.line_item_fields.some((o: any) => search.includes(`${o.key}:`))
        ) {
          type = 'line_item'
        }

        if (type === 'company') {
          promises.push(this.searchCompanyOptions(search, 'value'))
        } else if (type === 'user') {
          promises.push(this.searchUserOptions(search, 'value'))
        } else if (type === 'profile') {
          promises.push(this.searchProfileOptions(search, 'value'))
        } else if (type === 'media_plan') {
          promises.push(this.searchMediaPlanOptions(search, 'value'))
        } else if (type === 'invoice') {
          promises.push(this.searchInvoiceOptions(search, 'value'))
        } else if (type === 'geo_location') {
          promises.push(this.searchGeoLocationOptions(search, 'value'))
        } else if (type === 'order') {
          promises.push(this.searchOrderOptions(search, 'value'))
        } else if (type === 'line_item') {
          promises.push(this.searchLineItemOptions(search, 'value'))
        } else if (
          !this.settings.custom_fields
          || !this.settings.custom_fields.some((o: any) => o.value == search)
        ) {
          this.custom_options.push({
            name: search,
            value: search,
          })
        }
      })

      promises.push(this.filterCustomOptions(''))

      if (promises.length == 0) {
        this.local_value = this.value
        this.ready = true
        return
      }
      Promise.all(promises).then(() => {
        this.local_value = this.value

        setTimeout(() => {
          this.ready = true
        }, 500)
      })

      return
    }

    this.local_value = this.value
    this.ready = true
  }

  public open() {
    this.searchOptions()
  }
}
