import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import LineItemModule from '@/store/model/LineItemModule'
import { DateTime } from 'luxon'
import moment from 'moment'
import numeral from 'numeral'
import BigNumber from 'bignumber.js'
// @ts-ignore
import { Md5 } from 'ts-md5/dist/md5'
import SelectOption from './interface/SelectOption'
import Model from './interface/Model'
import { Targetting } from './interface/LineItemTypes'
import Api from './Api'
import PaginateOptions from './interface/PaginateOptions'
import Order from './Order'
import MediaPlanItem from './MediaPlanItem'
import MediaPlan from './MediaPlan'
import Company from './Company'
import { DATE_TIME_FORMAT, randomUUID, toDateTime } from './interface/Common'
import WebMessage from './WebMessage'
import Issue from './Issue'
import Creative from './Creative'
import AssociatedCreative from './AssociatedCreative'
import DistributionGoal from './DistributionGoal'

interface ExpectedCreatives {
  size: Number
  companion_size: Number
  count: Number
  creative_targeting: any
  ad_unit_frequency_cap: any
  native_media_orientation: any
  _expanded: any
}

export default class LineItem extends Model {
  public api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'line_item' as string | null,
      plural: 'line_items' as string | null,
    },
  }

  public api: any = new Api()

  public id: string | null = null

  public name: string = ''

  public ad_type: string = 'video'

  public adserver: string = 'cim'

  public billing_contract: string | null = null

  public media_plan_id: string | null = null

  public media_plan_item_id: string | null = null

  public order_id: string | null = null

  public advertiser_id: string | null = null

  public agency_id: string | null = null

  public station_id: string | null = null

  public account_executive_id: string | null = null

  public sales_rep_id: string | null = null

  public admanager_external_id: string | null = null

  public agency: Company = new Company()

  public advertiser: Company = new Company()

  public station: Company = new Company()

  public media_plan: MediaPlan = new MediaPlan()

  public media_plan_item: MediaPlanItem = new MediaPlanItem()

  public order: Order = new Order()

  public admanager_id: number | null = null

  public trafficker_id: any = null

  public secondary_trafficker_id: any = null

  public archived: boolean = false

  public budget: number = 0

  public budget_currency: string = 'usd'

  public cost: number = 0

  public cost_currency: string = 'usd'

  public cost_type: string | null = ''

  public created_at: string | null = null

  public delivery_rate: string | null = null

  public start_at: string = DateTime.now().startOf('day').toFormat(DATE_TIME_FORMAT)

  public end_at: string = DateTime.now().endOf('day').toFormat(DATE_TIME_FORMAT)

  public metrics: any = null

  public notes: any = ''

  public status: any = ''

  public allow_fallback: boolean = true

  public no_pod_targeting: boolean = false

  public remote_pod_targeting: number[] = [1, 2, 3, 4]

  public remote_asap: boolean = false

  public remote_ignore_targeting: boolean = false

  public remote_buffer_percentage: number = 0

  public real_time_metrics = { total_impressions: 0, daily_impressions: 0 }

  public updated_at: string | null = null

  public item_issues: { issues: Issue[]; total: number } = {
    issues: [],
    total: 0,
  }

  public history_notes_count: number = 0

  public disable_advertiser_exception: boolean = false

  // Check if object has changed
  public get is_dirty(): boolean {
    return this._hash !== this.calculateChecksum()
  }

  private _hash: string | Int32Array = ''

  private calculateChecksum(): string | Int32Array {
    let data = this.apiData

    return Md5.hashStr(JSON.stringify(data))
  }

  public updateChecksum() {
    this._hash = this.calculateChecksum()
  }

  public get status_name() {
    return this.archived ? 'Archived' : this.status.replaceAll('_', ' ')
  }

  public get status_color() {
    if (this.archived) {
      return 'secondary'
    }
    if (this.status == 'delivering') {
      return 'success'
    }
    if (this.status == 'pending_creative') {
      return 'danger'
    }
    if (this.status == 'paused') {
      return 'warning'
    }
    return 'info'
  }

  // public inventory: any = []

  public tracking_events: any = []

  public targetting: Targetting = {
    target_time: 'system',
    active: false,
    time_restrictions: [],
    include_dmas: [],
    exclude_dmas: [],
    include_countries: [],
    exclude_countries: [],
    include_states: [],
    exclude_states: [],
    include_cities: [],
    exclude_cities: [],
    include_zips: [],
    exclude_zips: [],
    include_devices: [],
    exclude_devices: [],
    include_ip: [],
    exclude_ip: [],
    include_key_values: [],
    exclude_key_values: [],
    include_adunits: [],
    exclude_adunits: [],
  }

  private get computed_targets() {
    return this.targetting
  }

  public get geo_targeting_list() {
    // map {type: 'dma', value: '501'},{type:'state', value: 'NY'},{type:'city', value: 'NYC'},{type:'zip', value: '10001'
    let mapper = (type: string, arr: any[]) => arr.map(a => ({ type, value: a }))
    return {
      includes: [
        ...mapper('country', this.computed_targets.include_countries),
        ...mapper('dma', this.computed_targets.include_dmas),
        ...mapper('state', this.computed_targets.include_states),
        ...mapper('city', this.computed_targets.include_cities),
        ...mapper('zip', this.computed_targets.include_zips),
      ],
      excludes: [
        ...mapper('country', this.computed_targets.exclude_countries),
        ...mapper('dma', this.computed_targets.exclude_dmas),
        ...mapper('state', this.computed_targets.exclude_states),
        ...mapper('city', this.computed_targets.exclude_cities),
        ...mapper('zip', this.computed_targets.exclude_zips),
      ],
    }
  }

  public get empty_geo() {
    return this.geo_targeting_list.includes.length == 0 || !this.geo_targeting_list.includes
  }

  public get empty_inventory() {
    return (
      this.targetting.include_adunits.length == 0 && this.targetting.exclude_adunits.length == 0
    )
  }

  public get only_us_targeted() {
    if (!this.geo_targeting_list) return false
    return (
      this.geo_targeting_list.includes.some(c => c.value === 'US' && c.type === 'country')
      && this.geo_targeting_list.includes.length === 1
    )
  }

  public before_save_issues_msg() {
    let base = '<p>Please review the following errors:</p>'
    if (this.empty_geo) {
      base += '<p>Geo Targeting: Empty</p>'
    }
    if (this.only_us_targeted) {
      base += '<p>Geo Targeting: Only national is selected</p>'
    }
    if (this.empty_inventory) {
      base += '<p>Inventory Targeting: Empty</p>'
    }
    return base
  }

  public distribution_goals: any[] = []

  public allow_ignore_distribution_goal: boolean = false

  public daily_cap: number = 0

  public frequency_caps_active = false

  public frequency_caps: any = []

  public open_ended: boolean = false

  public quantity: any = 0

  public contracted_amount: number = 0

  public buffer_rule: any = 'none'

  public get cloned() {
    if (!this.name || !this.name.length) return false
    return this.name.toLowerCase().includes('copy')
  }

  public get formBufferRule() {
    return this.buffer_rule
  }

  public set formBufferRule(value) {
    if (value != this.buffer_value) {
      if (value === 'none') {
        this.buffer_percentage = 0
        this.buffer_value = 0
      } else if (value === 'percentage') {
        this.buffer_value = 0
      } else if (value === 'fixed') {
        this.buffer_percentage = 0
      }
    }
    this.buffer_rule = value
  }

  public buffer_percentage: number = 0

  public buffer_value: number = 0

  public associated_creatives: any = []

  public remove_creatives: string[] = []

  public type: string = 'standard'

  public get formType() {
    return this.type
  }

  public set formType(value) {
    if (value !== this.type) {
      if (value === 'sponsorship') {
        this.priority = 4
      } else if (value === 'backfill') {
        this.priority = 13
      } else {
        this.priority = 8
      }
    }
    this.type = value
  }

  public priority: number = 8

  public expected_creatives: Array<ExpectedCreatives> = [
    {
      size: 0,
      companion_size: 0,
      count: 1,
      creative_targeting: '',
      ad_unit_frequency_cap: null,
      native_media_orientation: null,
      _expanded: true,
    },
  ]

  public template: any = null

  public creative_rotation: string = 'even'

  public frontload_percentage: any = 40

  public child_directed_ads: boolean = false

  public model: any = 'cpm'

  public pacing: any = 'even'

  public custom_targetting: any = []

  public media_plan_conflicts: any = []

  public replace_adunit: boolean = true

  public replace_creatives: boolean = true

  public override_distribution_goal: boolean = true

  public replace_frequency_caps: boolean = true

  public replace_tracking_events: boolean = true

  public custom_inventory: boolean = false

  public media_package_id: any = null

  public delivery_boost: number = 0

  public get enableDeliveryBoost() {
    return this.delivery_boost > 0
  }

  public set enableDeliveryBoost(value) {
    if (!value) {
      this.delivery_boost = 0
      return
    }

    WebMessage.doubleConfirm(
      'Are you sure that you want to enable the delivery boost? Enabiling this feature might cause overdelivery.',
      'Enable Delivery Boost',
      "Yes, I understand and I'll review this like item closely.",
      { okTitle: 'Enable' },
    ).then(result => {
      if (result) {
        this.delivery_boost = 2
      } else {
        this.delivery_boost = 1
        setTimeout(() => {
          this.delivery_boost = 0
        }, 100)
      }
    })
  }

  public get booked(): number {
    return (this.budget / this.cost) * 1000
  }

  public get revenue(): number {
    return (this.metrics.impressions * this.cost) / 1000
  }

  public get daily_pacing() {
    if (this.metrics.daily === 0 || this.metrics?.daily?.length == 0) {
      return [0, 0, 0, 0, 0, 0, 0]
    }

    return this.metrics.daily
  }

  public get delta_diff() {
    let diff = this.real_time_metrics.total_impressions - this.metrics.impressions
    return `${numeral(diff).format('0,0')} (${numeral(
      diff / this.real_time_metrics.total_impressions,
    ).format('0.00%')})`
  }

  public get apiData() {
    let data = {
      id: this.id,
      name: this.name,
      ad_type: this.ad_type,
      adserver: this.adserver,
      billing_contract: this.billing_contract,
      order_id: this.order_id,
      advertiser_id: this.advertiser_id,
      agency_id: this.agency_id,
      station_id: this.station_id,
      account_executive_id: this.account_executive_id,
      sales_rep_id: this.sales_rep_id,
      admanager_external_id: this.admanager_external_id,
      admanager_id: this.admanager_id,
      trafficker_id: this.trafficker_id,
      secondary_trafficker_id: this.secondary_trafficker_id,
      archived: this.archived,
      budget: this.budget,
      budget_currency: this.budget_currency,
      cost: this.cost,
      cost_currency: this.cost_currency,
      created_at: this.created_at,
      updated_at: this.updated_at,
      creative_rotation: this.creative_rotation,
      delivery_rate: this.delivery_rate,
      start_at: this.start_at,
      end_at: this.end_at,
      metrics: this.metrics,
      notes: this.notes,
      tracking_events: this.tracking_events,
      replace_tracking_events: this.replace_tracking_events,
      targetting: this.targetting,
      distribution_goals: this.distribution_goals,
      allow_ignore_distribution_goal: this.allow_ignore_distribution_goal,
      daily_cap: this.daily_cap,
      frequency_caps: this.frequency_caps_active ? this.frequency_caps : [],
      open_ended: this.open_ended,
      contracted_amount: this.contracted_amount,
      buffer_rule: this.buffer_rule,
      buffer_percentage: this.buffer_rule === 'percentage' ? this.buffer_percentage / 100 : 0,
      buffer_value: this.buffer_rule === 'fixed' ? this.buffer_value : 0,
      type: this.type,
      priority: this.priority,
      expected_creatives: this.expected_creatives,
      template: this.template,
      frontload_percentage: this.frontload_percentage,
      child_directed_ads: this.child_directed_ads,
      model: this.model,
      pacing: this.pacing,
      custom_targetting: this.custom_targetting,
      associated_creatives: this.associated_creatives,
      remove_creatives: this.remove_creatives,
      status: this.status,
      media_plan_id: this.media_plan_id,
      media_plan_item_id: this.media_plan_item_id,
      allow_fallback: this.allow_fallback,
      custom_inventory: this.custom_inventory,
      media_package_id: this.media_package_id,
      delivery_boost: this.delivery_boost,
      no_pod_targeting: this.no_pod_targeting,
      remote_asap: this.remote_asap,
      remote_ignore_targeting: this.remote_ignore_targeting,
      disable_advertiser_exception: this.disable_advertiser_exception,
      remote_pod_targeting: this.remote_pod_targeting,
      remote_buffer_percentage: this.remote_buffer_percentage / 100,
    }

    return data
  }

  public toObject(source: any) {
    let instance = this.clone()

    instance.metrics = {
      ...instance.metrics,
      ...source.metrics,
    }
    if (source.order) {
      instance.order = Order.toObject(source.order)
    }
    if (source.agency) {
      instance.agency = Company.toObject(source.agency)
    }
    if (source.advertiser) {
      instance.advertiser = Company.toObject(source.advertiser)
    }
    if (source.station) {
      instance.station = Company.toObject(source.station)
    }

    if (source.media_plan) {
      instance.media_plan = MediaPlan.toObject(source.media_plan)
    }

    if (source.media_plan_item) {
      instance.media_plan_item = MediaPlanItem.toObject(source.media_plan_item)
    }

    if (source.start_at) {
      instance.start_at = toDateTime(source.start_at).toFormat(DATE_TIME_FORMAT)
    }

    if (source.end_at) {
      instance.end_at = toDateTime(source.end_at).toFormat(DATE_TIME_FORMAT)
    }

    if (source.frequency_caps && source.frequency_caps.length) {
      instance.frequency_caps_active = true
    }

    if (source.buffer_percentage > 0) {
      instance.buffer_percentage = new BigNumber(source.buffer_percentage).times(100).toNumber()
    }

    if (source.remote_buffer_percentage > 0) {
      instance.remote_buffer_percentage = new BigNumber(source.remote_buffer_percentage)
        .times(100)
        .toNumber()
    }

    if (source.associated_creatives) {
      instance.associated_creatives = source.associated_creatives.map((a: any) => {
        a.creative = Creative.toObject(a.creative)
        a.status = 'Saved'
        return a
      })
    }

    if (source.hasOwnProperty('history_notes_count')) {
      instance.history_notes_count = source.history_notes_count
    }

    // Remove dynamic data
    if (source.metrics !== undefined) delete source.metrics
    if (source.order !== undefined) delete source.order
    if (source.agency !== undefined) delete source.agency
    if (source.advertiser !== undefined) delete source.advertiser
    if (source.station !== undefined) delete source.station
    if (source.media_plan !== undefined) delete source.media_plan
    if (source.media_plan_item !== undefined) delete source.media_plan_item
    if (source.start_at !== undefined) delete source.start_at
    if (source.end_at !== undefined) delete source.end_at
    if (source.buffer_percentage !== undefined) delete source.buffer_percentage
    if (source.remote_buffer_percentage !== undefined) delete source.remote_buffer_percentage
    if (source.associated_creatives !== undefined) delete source.associated_creatives

    if (source.priority && !source.type) {
      let type = source.priority === 4 ? 'sponsorship' : 'standard'
      source.type = type
    }

    if (source.targetting.time_restrictions.length) {
      source.targetting.time_restrictions = this.checkTimeRestriction(
        source.targetting.time_restrictions,
      )
    }

    Object.assign(instance, source)

    setTimeout(() => {
      instance.updateChecksum()
    }, 500)

    return instance
  }

  public checkTimeRestriction(restrictions: any) {
    return restrictions.map((res: any) => {
      let r = res
      if (!res.hasOwnProperty('use_specific_dates')) {
        r.use_specific_dates = false
      }
      return r
    })
  }

  protected static onSave(response: any) {
    return response
  }

  private static onError(error: any) {
    return error
  }

  public getHistory(options: PaginateOptions) {
    const api = new Api()
    return api.post(`line_item/${this.id}/history`, options)
  }

  public static bulkStatusChange(line_items_id: any, status: string) {
    const api = new Api()
    return api
      .post('line_item/bulk_status_change', {
        line_items_id,
        status,
      })
      .then(response => {
        WebMessage.success('Status updated')

        return response
      })
  }

  public play() {
    return LineItem.bulkStatusChange([this.id], 'active').then(response => {
      this.status = 'delivering'
    })
  }

  public pause() {
    return LineItem.bulkStatusChange([this.id], 'paused').then(response => {
      this.status = 'paused'
    })
  }

  public async archiveLineItem(val: boolean = true) {
    this.archived = val
    if (this.archived) {
      this.status = 'paused'
    }

    return this.save().then(res => res)
  }

  public static comparingHtmlBlock(key: string, { order, line_item, line_item_key }: any) {
    return `<div class="mt-2 mb-4">
    <div class="row w-100">
      <div class="col-12 col-md-6">
          <div class="alert alert-dark">
            <small>Order ${key}</small>
            <p class="p-0 m-0">${order}</p>
          </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="alert alert-soft-warning">
          <small>Line Item ${line_item_key || key}</small>
          <p class="p-0 m-0">${line_item}</p>
        </div>
      </div>
    </div>
  </div>`
  }

  public checkOrderInformation(cb: any) {
    let issues: any = []
    let msg = null

    if (!this.order) {
      return cb(issues)
    }

    if (this.contracted_amount > this.order.contracted_amount && !this.order.open_ended) {
      msg = LineItem.comparingHtmlBlock('contracted amount', {
        order: this.order.contracted_amount,
        line_item: this.contracted_amount,
      })
      issues.push({
        message: `Line item contracted amount exceeds the Order contracted amount.${msg}`,
        error: true,
      })
    }

    if (moment(this.start_at).isBefore(this.order.start_at)) {
      msg = LineItem.comparingHtmlBlock('start at', {
        order: this.order.start_at,
        line_item: this.start_at,
      })
      issues.push({
        message: `Line item starts before the Order starting date.${msg}`,
        error: true,
      })
    }

    if (moment(this.end_at).isAfter(this.order.end_at)) {
      msg = LineItem.comparingHtmlBlock('end at', {
        order: this.order.end_at,
        line_item: this.end_at,
      })
      issues.push({
        message: `Line item ends after the Order ending date.${msg}`,
        error: true,
      })
    }

    return cb(issues)
  }

  public batchUpdate(ids: string[], selected_inputs: string[]) {
    let props = selected_inputs.map(s => s.replaceAll('lineItem.', ''))

    let payload: any = {}

    let temp: any = this.apiData

    props.forEach((p: string) => {
      if (p.includes('targetting')) {
        const prop = p.replaceAll('targetting.', '')

        if (!payload.targetting) payload.targetting = {}

        // @ts-ignore
        payload.targetting[prop] = this.targetting[prop]
      } else if (p === 'delivery') {
        let temp_delivery: any = {
          pacing: this.pacing,
        }
        if (this.pacing === 'frontloaded') {
          temp_delivery.frontload_percentage = this.frontload_percentage
        }
        payload = {
          ...payload,
          ...temp_delivery,
        }
      } else if (p === 'creative_rotation') {
        let temp_delivery: any = {
          creative_rotation: this.creative_rotation,
        }

        payload = {
          ...payload,
          ...temp_delivery,
        }
      } else if (p === 'adunits') {
        if (!payload.targetting) payload.targetting = {}

        if (this.targetting.include_adunits.length) {
          payload.targetting.include_adunits = this.targetting.include_adunits
        }

        if (this.targetting.exclude_adunits.length) {
          payload.targetting.exclude_adunits = this.targetting.exclude_adunits
        }

        payload.replace_adunit = this.replace_adunit
      } else if (p === 'buffer_percentage') {
        payload[p] = this.buffer_percentage / 100
        payload.buffer_rule = 'percentage'
      } else if (p === 'remote_buffer_percentage') {
        payload[p] = this.remote_buffer_percentage / 100
        payload.buffer_rule = 'percentage'
      } else if (p === 'type') {
        payload[p] = this.type
        payload.priority = this.priority
      } else {
        payload[p] = temp[p]
      }
    })

    // check for include_adunits and exclude_adunits
    if (props.includes('distribution_goals')) {
      payload.override_distribution_goal = this.override_distribution_goal
    }

    if (this.associated_creatives.length) {
      payload.associated_creatives = this.associated_creatives
      payload.replace_creatives = this.replace_creatives
    }

    if (props.includes('frequency_caps')) {
      payload.replace_frequency_caps = this.replace_frequency_caps
    }

    if (props.includes('tracking_events')) {
      payload.replace_tracking_events = this.replace_tracking_events
    }

    let api = new Api()

    return api.post('line_item/batch-update', {
      ids,
      payload,
    })
  }

  public static async getLatestLineItems() {
    let api = new Api()

    return api
      .get('line_items/latest-items')
      .then(response => response.data.result.line_items)
      .catch(error => [])
  }

  public static async loadCreativesFromLineItems(line_item_ids: String[]) {
    let api = new Api()

    return api
      .post('line_item/creatives', {
        line_item_ids,
      })
      .then(response => response.data.result.creatives)
      .catch(error => [])
  }

  public static batchCopyCreate(line_item_ids: any[], payload: any) {
    let api = new Api()

    return api
      .post('line_item/batch-copy', {
        line_item_ids,
        payload,
      })
      .then(response => response.data.result.line_items)
      .catch(error => [])
  }
}
