import moment from 'moment'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import { startCase } from 'lodash'
import dmas from '@/data/dmas'
import BigNumber from 'bignumber.js'
import MediaPlanItem from './MediaPlanItem'
import User from './User'
import Company from './Company'
import Api from './Api'
import Terms from './Terms'
import { groups, metrics, tableFields } from './metadata/MediaPlanMetadata'
import MediaPackage from './MediaPackage'
import Opportunity from './Opportunity'
import ModelWithFiles from './interface/ModelWithFiles'
import AssociatedCreative from './AssociatedCreative'
import Creative from './Creative'

export type Schedule = {
  [key: string]: number
}
export default class MediaPlan extends ModelWithFiles {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'media_plan' as string | null,
      plural: 'media_plans' as string | null,
    },
  }

  public name: string = ''

  public type: string = 'default'

  public number: number = 0

  public agency_id: string | null = null

  public agency: Company | null = null

  public station_id: string | null = null

  public station: Company | null = null

  public advertiser_id: string = ''

  public advertiser: Company | null = null

  public contact_company: string = ''

  public contact_name: string = ''

  public contact_title: string = ''

  public contact_email: string = ''

  public sales_management_id: string | null = '92fb454e-7f5e-4fb5-a3d5-6b76756104ab'

  public sales_management: User | null = null

  public sales_rep_id: string | null = ''

  public sales_rep: User | null = null

  public secondary_sales_rep_ids: string | null = ''

  public secondary_sales_reps: User[] = []

  public account_manager_id: string | null = ''

  public account_manager: User | null = null

  public line_items: MediaPlanItem[] = []

  public status: string = 'draft'

  public agency_commission_model: string = 'none'

  public agency_commission: number = 0

  public created_at: string = ''

  public updated_at: string = ''

  public download_on_save: boolean = false

  public proposal_action: string = 'New'

  public salesforce_opportunity_id: string | null = null

  public opportunity_id: string | null = null

  public opportunity: Opportunity | null = null

  public terms_id: string | null = null

  public terms: string | null = null

  public metrics: any = {}

  public max_avails: number = 0

  public grps: number = 0

  public _impressions: number = 0

  public dynamic_rate_id: string | null = null

  public billing_info: any = {}

  public client_id: string = ''

  public close_reason: any = null

  public lost_category: any = null

  public billing_schedule: Schedule = {}

  public revenue_schedule: Schedule = {}

  public cost_schedule: Schedule = {}

  public export_settings: any = {
    overview_creative: null,
  }

  public default_values: any = {
    items: {
      rate: null,
      notes: null,
    },
    targets: {
      vcr: 0,
      ctv: 0,
      live: 0,
      audience: false,
      foot_traffick: false,
    },
  }

  public get items_billing_schedule_totals() {
    // sum all line_items.*.billing_schedule
    return this.line_items.reduce((acc: number, item) => {
      for (const [key, value] of Object.entries(item.billing_schedule)) {
        acc = (Number(acc) || 0) + Number(value)
      }
      return acc
    }, 0)
  }

  public get items_revenue_schedule_totals() {
    return this.line_items.reduce((acc: number, item) => {
      for (const [key, value] of Object.entries(item.revenue_schedule)) {
        acc = (Number(acc) || 0) + Number(value)
      }
      return acc
    }, 0)
  }

  public get items_billing_schedule() {
    // line_items.*.billing_schedule
    return this.line_items.reduce((acc: any, item) => {
      for (const [key, value] of Object.entries(item.billing_schedule)) {
        acc[key] = (Number(acc[key]) || 0) + Number(value)
      }
      return acc
    }, {})
  }

  public get items_revenue_schedule() {
    return this.line_items.reduce((acc: any, item) => {
      for (const [key, value] of Object.entries(item.revenue_schedule)) {
        acc[key] = (Number(acc[key]) || 0) + Number(value)
      }
      return acc
    }, {})
  }

  public _loading: boolean = false

  public get cost_schedule_total(): number {
    let total: any = Object.values(this.cost_schedule).reduce(
      (a: any, b: any) => Number(a) + Number(b),
      0,
    )
    return total ?? 0
  }

  // billing_schedule = {"jan_2024":100,"jan_2025":100,"feb_2025":100}
  public get billing_schedule_total(): number {
    // sum line_items.*.billing_schedule
    let total: any = Object.values(this.billing_schedule).reduce(
      (a: any, b: any) => Number(a) + Number(b),
      0,
    )
    return total ?? 0
  }

  public get revenue_schedule_total(): number {
    let total: any = Object.values(this.revenue_schedule).reduce(
      (a: any, b: any) => Number(a) + Number(b),
      0,
    )
    return total || 0
  }

  public get schedule_exceeds() {
    return {
      billing_schedule_total: this.billing_schedule_total > this.ccl_revenue,
      revenue_schedule_total: this.revenue_schedule_total > this.ccl_revenue,
    }
  }

  public get contains_ccl_product() {
    if (!this.line_items.length) return false
    return this.line_items.some(item => item.product === 'ccl')
  }

  public get ccl_revenue() {
    return this.line_items
      .filter((item: MediaPlanItem) => item.product === 'ccl')
      .reduce((acc, item) => acc + item.formNetCost, 0)
  }

  public get secondary_sales_rep_names() {
    if (this.secondary_sales_reps.length === 0) {
      return 'N/A'
    }
    return this.secondary_sales_reps.map(rep => rep.name).join(', ')
  }

  public get demo_target_names() {
    if (!this.isLinear || !this.metadata.demo_targets || this.metadata.demo_targets.length === 0) {
      return 'N/A'
    }
    return this.metadata.demo_targets
      .map((demo: any) => `${demo.target}${demo.age_low}${demo.age_high}`)
      .join(', ')
  }

  public get info_acknolwedged() {
    return this.metadata.invoice.info_acknolwedged
  }

  public set info_acknolwedged(info_acknolwedged: boolean) {
    this.metadata.invoice.info_acknolwedged = info_acknolwedged
  }

  public get billing_custom() {
    return this.metadata.invoice.billing_custom
  }

  public set billing_custom(billing_custom: boolean) {
    this.metadata.invoice.billing_custom = billing_custom
  }

  public get billing_client_id() {
    if (
      this.metadata.invoice.billing_info_source
      && this.metadata.invoice.billing_info_source === 'station'
    ) {
      return this.station_id
    }
    return this.metadata.invoice.billing_info_source
      && this.metadata.invoice.billing_info_source === 'advertiser'
      ? this.advertiser_id
      : this.agency_id
  }

  public metadata: any = {
    view_columns: ['model', 'net_rate', 'net_cost'],
    invoice: {
      tempalte_id: 'default',
      payment_terms: 'net_30',
      delivery_driver: 'email',
      billing_info_source: '',
      billing_custom: false,
      info_acknolwedged: false,
      billing_info: {
        country: 'US',
        state: '',
        city: '',
        zipcode: '',
        billing_email: [],
        billing_phone: '',
        address_line_1: '',
        address_line_2: '',
        address_line_3: '',
        address_line_4: '',
      },
    },
    client_data: {
      name: '',
      order: '',
      product: '',
      estimate: '',
      product_estimate: '',
    },
    agency: {
      id: '',
      name: '',
      address_line_1: '',
      address_line_2: '',
      address_line_3: '',
      address_line_4: '',
    },
    station: {
      call_letters: '',
      is_dv: false,
    },
    header: {
      representative: '',
      advertiser_name: '',
      product_name: '',
      agency_estimate_code: '',
      start_date: '',
      end_date: '',
      station_order_number: '',
      station_trade_order_number: '',
      agency_advertiser_code: '',
      agency_product_code: '',
      trading_partner_code: '',
      rep_id: '',
      special_paying_rep_code: '',
    },
    demo_targets: [
      {
        target: 'AD',
        age_low: '25',
        age_high: '54',
      },
    ],
    isci_required: false,
    cash_io: null,
    trade_io: null,
    billing_cycle: 'monthly',
    invoice_mode: 'platform',
    proposal: {
      messages: [],
    },
    order_total_cash: 0,
    order_total_trade: 0,
    targets: {
      vcr: 80,
      ctv: 45,
      live: 0,
    },
  }

  /**
   * UI Form Fields
   */
  public get has_trade_item() {
    return this.line_items.some(item => item.metadata.order_type === 'trade')
  }

  public get hasNotes() {
    return this.line_items.some(
      item =>
        item.metadata.notes !== null
        && typeof item.metadata.notes === 'string'
        && item.metadata.notes.replaceAll(' ', '') != '',
    )
  }

  public get projected_revenue(): number {
    return this.line_items.reduce((acc, item) => acc + item.projected_revenue, 0)
  }

  public get projected_net_revenue(): number {
    return this.line_items.reduce((acc, item) => acc + item.projected_net_revenue, 0)
  }

  public get projected_media_cost(): number {
    return this.line_items.reduce((acc, item) => acc + item.projected_media_cost, 0)
  }

  public get projected_profitability(): number {
    return this.projected_net_revenue / this.net_cost
  }

  public get projected_media_rate_cost(): number {
    return this.projected_media_cost / (this.impressions / 1000)
  }

  public get elegible_trade_spots() {
    let pointer = moment(this.start_at)
    let weeks = 0
    let spots = 0
    let month = pointer.month()
    while (pointer.isBefore(this.end_at)) {
      if (pointer.month() !== month) {
        if (weeks >= 1) spots++
        weeks = 0
        month = pointer.month()
      }
      pointer.add(1, 'week')
      if (pointer.month() === month) weeks++
    }

    if (weeks >= 1) spots++

    return spots
  }

  public get formSFOpportunityURL(): string {
    return `${process.env.VUE_APP_SALESFORCE_INSTANCE_URL}/lightning/r/Opportunity/${this.salesforce_opportunity_id}/view`
  }

  public get formName(): string {
    return this.name !== '' ? `#${this.number} ${this.name}` : 'Untitled Media Plan'
  }

  public get fullName(): string {
    return this.name !== '' ? `#${this.number} ${this.name}` : 'N/A'
  }

  public get formDemos() {
    return this.metadata.demo_targets
  }

  public set formDemos(demos: any) {
    this.metadata.demo_targets = demos
  }

  public get formAgencyCommissionModel() {
    return this.agency_commission_model
  }

  public set formAgencyCommissionModel(agency_commission_model: string) {
    this.agency_commission_model = agency_commission_model
    this.line_items.forEach(i => {
      if (i.agency_commission_model != this.agency_commission_model) {
        i.formAgencyCommissionModel = this.agency_commission_model
      }
    })
  }

  public get cashGrossCost(): number {
    return this.line_items.reduce((total, item) => {
      if (item.metadata.order_type === 'cash') total += item.gross_cost
      return total
    }, 0)
  }

  public get tradeGrossCost(): number {
    return this.line_items.reduce((total, item) => {
      if (item.metadata.order_type === 'trade') total += item.gross_cost
      return total
    }, 0)
  }

  public get cashDiff(): number {
    return Math.abs(this.cashGrossCost - Number(this.metadata.order_total_cash))
  }

  public get tradeDiff(): number {
    return Math.abs(this.tradeGrossCost - Number(this.metadata.order_total_trade))
  }

  public get formAgencyCommission() {
    return this.agency_commission
  }

  public set formAgencyCommission(agency_commission: number) {
    this.agency_commission = Number(agency_commission)
    this.line_items.forEach(i => {
      if (i.agency_commission != this.agency_commission) {
        i.formAgencyCommission = this.agency_commission
      }
    })
  }

  public get formStationCallLetters(): string {
    return this.metadata.station.call_letters
  }

  public set formStationCallLetters(station: string) {
    if (station && station.length === 4 && station !== this.metadata.station.call_letters) {
      const code = station.slice(-2)
      const dma = dmas.find(dma => dma.media_ocean_station === code)
      if (dma) {
        this.line_items.forEach(item => {
          item.metadata.targetting.include.dmas = [dma.id]
        })
      }
    }
    this.metadata.station.call_letters = station
  }

  public get spots(): number[] {
    const ret = []
    const pointer = moment(this.start_at)
    const end = moment(this.end_at)

    while (pointer.isBefore(end)) {
      let sum = 0

      this.line_items.forEach(item => {
        if (pointer.isSameOrAfter(item.start_at) && pointer.isSameOrBefore(item.end_at)) {
          let week = pointer.diff(item.start_at, 'weeks')
          sum += item.metadata.spots[week] ?? 0
        }
      })

      ret.push(sum)

      pointer.add(1, 'week')
    }

    return ret
  }

  public get total_spots(): number {
    return this.spots.reduce(
      (carry, spot) => (typeof spot === 'string' ? parseInt(spot) + carry : spot + carry),
      0,
    )
  }

  public get bnImpressions() {
    return new BigNumber(this.impressions)
  }

  public get impressions() {
    if (this.line_items.length == 0) {
      return this._impressions
    }
    return this.line_items.reduce(
      (carry, item) =>
        (typeof item.impressions === 'string'
          ? parseInt(item.impressions) + carry
          : item.impressions + carry),
      0,
    )
  }

  public get isAllOpenEnded() {
    return this.line_items.every(item => item.open_ended)
  }

  public get gross_rate() {
    if (this.isAllOpenEnded) {
      return (
        this.line_items.reduce((carry, item) => carry + item.gross_rate, 0) / this.line_items.length
      )
    }

    let flat_rate_items = this.line_items.filter((l: MediaPlanItem) => l.model === 'flat')
    let default_rate_items = this.line_items.filter((l: MediaPlanItem) => l.model !== 'flat')

    let flat_cost = 0
    let flat_impressions = 0
    let default_cost = 0
    let default_impressions = 0

    if (flat_rate_items.length > 0) {
      flat_cost = +new BigNumber(
        flat_rate_items.reduce(
          (carry, item) =>
            (typeof item.gross_cost === 'string'
              ? Number(item.gross_cost) + carry
              : item.gross_cost + carry),
          0,
        ),
      )

      flat_impressions = flat_rate_items.reduce(
        (carry, item) =>
          (typeof item.impressions === 'string'
            ? Number(item.impressions) + carry
            : item.impressions + carry),
        0,
      )
    }

    if (default_rate_items.length > 0) {
      default_cost = +new BigNumber(
        default_rate_items.reduce(
          (carry, item) =>
            (typeof item.gross_cost === 'string'
              ? Number(item.gross_cost) + carry
              : item.gross_cost + carry),
          0,
        ),
      )

      default_impressions = default_rate_items.reduce(
        (carry, item) =>
          (typeof item.impressions === 'string'
            ? Number(item.impressions) + carry
            : item.impressions + carry),
        0,
      )

      default_impressions /= 1000
    }

    let total_cost = flat_cost + default_cost
    let total_impressions = flat_impressions + default_impressions

    return total_cost ? +new BigNumber(total_cost).div(total_impressions) : 0
  }

  public get net_rate() {
    if (this.isAllOpenEnded) {
      return (
        this.line_items.reduce((carry, item) => carry + item.net_rate, 0) / this.line_items.length
      )
    }

    let flat_rate_items = this.line_items.filter((l: MediaPlanItem) => l.model === 'flat')
    let default_rate_items = this.line_items.filter((l: MediaPlanItem) => l.model !== 'flat')

    let flat_cost = 0
    let flat_impressions = 0
    let default_cost = 0
    let default_impressions = 0

    if (flat_rate_items.length > 0) {
      flat_cost = +new BigNumber(
        flat_rate_items.reduce(
          (carry, item) =>
            (typeof item.net_cost === 'string'
              ? Number(item.net_cost) + carry
              : item.net_cost + carry),
          0,
        ),
      )

      flat_impressions = flat_rate_items.reduce(
        (carry, item) =>
          (typeof item.impressions === 'string'
            ? Number(item.impressions) + carry
            : item.impressions + carry),
        0,
      )
    }

    if (default_rate_items.length > 0) {
      default_cost = +new BigNumber(
        default_rate_items.reduce(
          (carry, item) =>
            (typeof item.net_cost === 'string'
              ? Number(item.net_cost) + carry
              : item.net_cost + carry),
          0,
        ),
      )

      default_impressions = default_rate_items.reduce(
        (carry, item) =>
          (typeof item.impressions === 'string'
            ? Number(item.impressions) + carry
            : item.impressions + carry),
        0,
      )

      default_impressions /= 1000
    }

    let total_cost = flat_cost + default_cost
    let total_impressions = flat_impressions + default_impressions

    return total_cost ? +new BigNumber(total_cost).div(total_impressions) : 0
  }

  public get gross_cost() {
    return this.line_items.reduce(
      (carry, item) =>
        (typeof item.gross_cost === 'string'
          ? Number(item.gross_cost) + carry
          : item.gross_cost + carry),
      0,
    )
  }

  public get net_cost() {
    return this.line_items.reduce(
      (carry, item) =>
        (typeof item.net_cost === 'string' ? Number(item.net_cost) + carry : item.net_cost + carry),
      0,
    )
  }

  public get start_at() {
    let ret: any = null

    this.line_items.forEach((item: MediaPlanItem) => {
      if (
        (ret == null && item.start_at)
        || (ret != null && item.start_at && ret.isAfter(moment(item.start_at)))
      ) {
        ret = moment(item.start_at)
      }
    })

    return ret ? ret.format('YYYY-MM-DD') : null
  }

  public get end_at() {
    let ret: any = null

    this.line_items.forEach((item: MediaPlanItem) => {
      if (
        (ret == null && item.end_at)
        || (ret != null && item.end_at && ret.isBefore(moment(item.end_at)))
      ) {
        ret = moment(item.end_at)
      }
    })

    return ret ? ret.format('YYYY-MM-DD') : null
  }

  public get flight_dates() {
    const start = moment(this.start_at)
    const end = moment(this.end_at)
    if (start && end) {
      return `${start.format('MMMM Do, YYYY')} - ${end.format('MMMM Do, YYYY')}`
    }
    return 'N/A'
  }

  public get formType() {
    return this.type
  }

  public set formType(type: string) {
    this.type = type
    if (this.isLinear) {
      this.metadata.view_columns = [
        'model',
        'net_rate',
        'gross_rate',
        'net_cost',
        'gross_cost',
        'grps',
      ]

      if (this.agency_commission == 0 && !this.id) {
        this.formAgencyCommissionModel = 'percentage'
        this.formAgencyCommission = 15

        this.metadata.billing_cycle = 'broadcast_month'
      }
    } else {
      this.metadata.view_columns = ['model', 'net_rate', 'net_cost']

      if (!this.id && this.agency_commission == 15) {
        this.formAgencyCommissionModel = 'none'
        this.formAgencyCommission = 0

        this.metadata.billing_cycle = 'monthly'
      }
    }

    this.line_items.forEach(i => {
      i.type = this.type
    })
  }

  public get formStatus() {
    return startCase(this.status.replaceAll('_', ' '))
  }

  public get formLostCategory() {
    if (!this.lost_category) return 'N/A'
    return startCase(this.lost_category.replaceAll('_', ' '))
  }

  public get lost_category_color(): string {
    if (!this.lost_category) return 'secondary'
    return 'danger'
  }

  public get status_color(): string {
    if (this.status === 'draft') {
      return 'secondary'
    }
    if (this.status === 'confirmed') {
      return 'success'
    }
    if (this.status === 'pending_revision') {
      return 'info'
    }
    if (['cancelled', 'lost'].includes(this.status)) {
      return 'danger'
    }
    return 'warning'
  }

  public get formTermsId() {
    return this.terms_id
  }

  public set formTermsId(value: any) {
    if (value != this.terms_id) {
      if (value) {
        Terms.get(value).then(t => {
          if (t instanceof Terms) this.terms = t.terms
        })
      } else this.terms = ''

      this.terms_id = value
    }
  }

  private _formSchedule: any = null

  public get formSchedule() {
    if (!this._formSchedule) {
      this._formSchedule = this.line_items.map(line_item => {
        let week = this.lineItemSpotIndex(line_item.start_at, 0)

        let start = moment(line_item.schedule_start_at)
        let end = moment(line_item.schedule_end_at)

        let name = `${line_item.name}: ${start.format('MM/DD')} - ${end.format('MM/DD')}`
        if (line_item.metadata.program_name) {
          name = `${line_item.metadata.program_name} (Week ${week}): ${start.format(
            'MM/DD',
          )} - ${end.format('MM/DD')}`
        }

        return {
          id: line_item.id,
          code: `${String(this.number).padStart(String(this.number).length + 1, '0')}-${String(
            line_item.number,
          ).padStart(3, '0')}`,
          name,
          start_at: line_item.schedule_start_at,
          end_at: line_item.schedule_end_at,
          days: line_item.metadata.days
            .map((day: string) => day.slice(0, 1).toUpperCase() + day.slice(1, 3))
            .join(', '),
          impressions: line_item.impressions,
          net_rate: line_item.net_rate,
          notes: line_item.notes,
          _showDetails: line_item._showDetails,
          metadata: line_item.metadata,
        }
      })
    }

    return this._formSchedule
  }

  public get formTerms() {
    return this.terms
  }

  public set formTerms(value: string | null) {
    if (value != this.terms) {
      this.terms = value
      this.terms_id = 'custom'
    }
  }

  public get isLinear() {
    return ['media_ocean', 'strata'].includes(this.type)
  }

  public get orderCashAndTradeIsRequired() {
    return (
      (this.metadata.order_total_cash > 0
        || this.metadata.order_total_trade > 0
        || moment(this.created_at).isAfter(moment('2023-05-07'))
        || moment(this.start_at).isSameOrAfter(moment('2023-06-01')))
      && this.status !== 'draft'
    )
  }

  // public get BillingTargetId(){
  //   if()
  // }

  constructor(source: any = {}) {
    super()

    // Remove dynamic data
    if (source.start_at) delete source.start_at
    if (source.end_at) delete source.end_at
    if (source.impressions) {
      this._impressions = source.impressions
      delete source.impressions
    }

    Object.assign(this, source)

    if (source.agency) {
      this.agency = Company.toObject(source.agency)
    }

    if (source.station) {
      this.station = Company.toObject(source.station)
    }

    if (source.advertiser) {
      this.advertiser = Company.toObject(source.advertiser)
    }

    if (source.sales_management) {
      this.sales_management = User.toObject(source.sales_management)
    }

    if (source.sales_rep) {
      this.sales_rep = User.toObject(source.sales_rep)
    }

    if (source.secondary_sales_reps) {
      this.secondary_sales_reps = User.toObjectList(source.secondary_sales_reps)
    }

    if (source.account_manager) {
      this.account_manager = User.toObject(source.account_manager)
    }

    if (source.opportunity) {
      this.opportunity = Opportunity.toObject(source.opportunity)
    }

    if (source.line_items) {
      this.line_items = MediaPlanItem.toObjectList(source.line_items).map(i => {
        i.type = this.type
        i.agency_commission = this.agency_commission
        i._showDetails = false

        return i
      })
    }

    return this
  }

  public toObject(source: any) {
    let instance = this.clone()

    // Remove dynamic data
    if (source.start_at !== undefined) delete source.start_at
    if (source.end_at !== undefined) delete source.end_at
    if (source.impressions !== undefined) {
      instance._impressions = source.impressions
      delete source.impressions
    }

    if (!source.revenue_schedule) {
      delete source.revenue_schedule
      instance.revenue_schedule = {}
    }
    if (!source.billing_schedule) {
      delete source.billing_schedule
      instance.billing_schedule = {}
    }

    if (!source.cost_schedule) {
      delete source.cost_schedule
      instance.cost_schedule = {}
    }

    Object.assign(instance, source)

    if (source.agency) {
      instance.agency = Company.toObject(source.agency)
    }

    if (source.station) {
      instance.station = Company.toObject(source.station)
    }

    if (source.advertiser) {
      instance.advertiser = Company.toObject(source.advertiser)
    }

    if (source.sales_management) {
      instance.sales_management = User.toObject(source.sales_management)
    }

    if (source.sales_rep) {
      instance.sales_rep = User.toObject(source.sales_rep)
    }

    if (source.account_manager) {
      instance.account_manager = User.toObject(source.account_manager)
    }

    if (source.line_items) {
      instance.line_items = MediaPlanItem.toObjectList(source.line_items)
        .map(i => {
          i.type = instance.type
          i.agency_commission = instance.agency_commission
          i._showDetails = false
          return i
        })
        .sort((a, b) => a.number - b.number)
    }

    const base = new MediaPlan()
    if (source.metadata) {
      instance.metadata = { ...base.metadata, ...source.metadata }
      instance.metadata.client_data = {
        ...base.metadata.client_data,
        ...source.metadata.client_data,
      }
      instance.metadata.invoice = { ...base.metadata.invoice, ...source.metadata.invoice }
    } else {
      instance.metadata = { ...base.metadata }
    }

    return instance
  }

  public addLineItem() {
    let features = []
    if (this.agency && this.agency.third_party_deal) {
      features.push('3PD')
    }
    if (this.isLinear) {
      features.push('AT')
    }
    const item = MediaPlanItem.toObject({
      number: this.line_items.length + 1,
      agency_commission: this.agency_commission,
      agency_commission_model: this.agency_commission_model,
      type: this.type,
      start_at: moment(this.end_at).add(1, 'days').format('YYYY-MM-DD'),
      end_at: moment(this.end_at).add(1, 'week').format('YYYY-MM-DD'),
      // @ts-ignore
      special_features: features,
    })

    if (this.default_values.items.notes) {
      item.notes = this.default_values.items.notes
    }

    if (this.default_values.targets.vcr) {
      item.metadata.targets.vcr = this.default_values.targets.vcr
    }
    if (this.default_values.targets.ctv) {
      item.metadata.targets.ctv = this.default_values.targets.ctv
    }
    if (this.default_values.targets.live) {
      item.metadata.targets.live = this.default_values.targets.live
    }
    if (this.default_values.targets.audience) {
      item.metadata.targets.audience = this.default_values.targets.audience
    }
    if (this.default_values.targets.foot_traffick) {
      item.metadata.targets.foot_traffick = this.default_values.targets.foot_traffick
    }

    if (this.metadata.station.call_letters?.length === 4) {
      const code = this.metadata.station.call_letters.slice(-2)
      const dma = dmas.find(dma => dma.media_ocean_station === code)
      if (dma) item.metadata.targetting.include.dmas = [dma.id]
    }

    if (this.agency) {
      if (this.agency.default_rate > 0) {
        if (this.isLinear) {
          item.gross_rate = this.agency.default_rate
        } else {
          item.net_rate = this.agency.default_rate
        }
      }
      if (this.agency.dynamic_rate_id && this.agency.dynamic_rate_id !== 'null') {
        item.setDynamicRate(this.agency.dynamic_rate_id)
      }
    }

    if (this.advertiser) {
      if (this.advertiser.default_rate > 0) {
        if (this.isLinear) {
          item.gross_rate = this.advertiser.default_rate
        } else {
          item.net_rate = this.advertiser.default_rate
        }
      }
      if (this.advertiser.dynamic_rate_id && this.advertiser.dynamic_rate_id !== 'null') {
        item.setDynamicRate(this.advertiser.dynamic_rate_id)
      }
    }

    if (this.default_values.items.rate) {
      if (this.isLinear) {
        item.formGrossRate = this.default_values.items.rate
      } else {
        item.formNetRate = this.default_values.items.rate
      }
    }

    if (this.advertiser_id && this.advertiser && this.advertiser.default_media_package_id) {
      item.media_package_id = this.advertiser.default_media_package_id
    } else if (this.agency_id && this.agency && this.agency.default_media_package_id) {
      item.media_package_id = this.agency.default_media_package_id
    }

    if (item.media_package_id && item.formGrossRate == 0) {
      MediaPackage.find(item.media_package_id).then((media_package: MediaPackage) => {
        if (item.formGrossRate == 0) {
          item.formGrossRate = media_package.rate
        }
      })
    }

    this.line_items.push(item)

    return item
  }

  public removeLineItem(number: number) {
    const items = this.line_items.filter(i => i.number != number)

    let count = 1
    items.forEach(i => {
      i.number = count++
    })
    this.line_items = items
  }

  public toggleAllDetails(state: boolean) {
    this.line_items.forEach(i => (i._showDetails = state))

    return state
  }

  public addDemoTarget() {
    this.metadata.demo_targets.push({
      target: 'AD',
      age_low: '25',
      age_high: '54',
    })
  }

  public removeDemoTarget(index: number) {
    this.metadata.demo_targets.splice(index, 1)
  }

  public loadLineItems() {
    let api = new Api()

    return api.get(`media_plan/${this.id}/line_items`).then((response: any) => {
      this.line_items = MediaPlanItem.toObjectList(response.data.items).map(i => {
        i.type = this.type
        i.agency_commission = this.agency_commission
        i._showDetails = false
        return i
      })
    })
  }

  public loadOrderData(order_data: any) {
    if (!order_data.order_cash && !order_data.order_trade) return this

    let base_type = order_data.order_cash ? 'order_cash' : 'order_trade'
    let order = order_data[base_type]

    // Assign Order data
    if (order.agency_name) {
      this.metadata.agency.name = order.agency_name
    }
    if (order.idb) {
      this.metadata.agency.id = order.idb
    }
    if (order.agency_address) {
      if (Array.isArray(order.agency_address)) {
        for (let i = 0; i < order.agency_address.length && i <= 3; i++) {
          this.metadata.agency[`address_line_${i + 1}`] = order.agency_address[i]
        }
      } else {
        this.metadata.agency.address_line_1 = order.agency_address
      }
    }
    if (order.client_name) {
      this.metadata.header.advertiser_name = order.client_name
    }
    if (order.product_name) {
      this.metadata.header.product_name = order.product_name
    }
    if (order.estimate_code) {
      this.metadata.header.agency_estimate_code = order.estimate_code
    }
    if (order.product_code) {
      this.metadata.header.agency_product_code = order.product_code
    }
    if (order.client_code) {
      this.metadata.header.agency_advertiser_code = order.client_code
    }
    if (order.buyer_name) {
      this.metadata.header.representative = order.buyer_name
    }
    if (order.start) {
      this.metadata.header.start_date = moment(order.start).format('YYMMDD')
    } else {
      this.metadata.header.start_date = moment(this.start_at).format('YYMMDD')
    }
    if (order.end) {
      this.metadata.header.end_date = moment(order.end).format('YYMMDD')
    } else {
      this.metadata.header.end_date = moment(this.end_at).format('YYMMDD')
    }
    if (order.invoice_mode) {
      this.metadata.invoice.delivery_driver = order.invoice_mode
    } else {
      this.metadata.invoice.delivery_driver = 'platform'
    }

    if (base_type === 'order_cash') {
      this.metadata.cash_io = order_data.order_cash.file
      this.metadata.header.station_order_number = order_data.order_cash.order_number
      if (order_data.order_trade) {
        this.metadata.header.station_trade_order_number = order_data.order_trade.order_number
        this.metadata.trade_io = order_data.order_trade.file
      }
    } else {
      this.metadata.header.station_trade_order_number = order_data.order_trade.order_number
    }

    return this
  }

  public async export(type: string, data: any = {}) {
    if (!this.id) {
      return Promise.reject(new Error('Media plan must be saved before it can be downloaded.'))
    }
    return MediaPlan.export([this.id], type, data)
  }

  public static async export(media_plans: string[], type: string, data: any = {}) {
    const instance_id = getModule(SystemtModule)._uuid
    const api = new Api()

    return api.post(`media_plans/export/${type}`, { media_plans, ...data, instance_id })
  }

  public static async uploadOrderFiles(order_cash: any, order_trade: any) {
    const instance_id = getModule(SystemtModule)._uuid
    const api = new Api()

    return api.form('media_plans/upload-order-files', {
      order_cash,
      order_trade,
      instance_id,
    })
  }

  public static async processOrderFiles(order_cash: any, order_trade: any) {
    const instance_id = getModule(SystemtModule)._uuid
    const api = new Api()

    return api.get('media_plans/process-order-files', {
      order_cash,
      order_trade,
      instance_id,
    })
  }

  public async getOpportunityData() {
    const instance_id = getModule(SystemtModule)._uuid
    const api = new Api()

    return api.get(`media_plan/opportunity/${this.salesforce_opportunity_id}`)
  }

  public async cancel({
    close_reason,
    lost_category,
  }: {
    close_reason: string
    lost_category: string
  }) {
    this.status = 'cancelled'
    this.close_reason = close_reason
    this.lost_category = lost_category
    return this.save()
  }

  public static buildVideoCopyId(media_plan: MediaPlan, line_item: MediaPlanItem) {
    let demo = media_plan.metadata.demo_targets[line_item.metadata.demo_target]

    return `I${demo.target}${demo.age_low}${demo.age_high}=${(line_item.impressions / 100)
      .toFixed(0)
      .replaceAll('.', '')}`
  }

  public lineItemSpotIndex(start_at: string, index: number): number {
    let pointer = moment(this.start_at).startOf('week').add(index, 'weeks').add(1, 'day')
    let start = moment(start_at).startOf('week').add(1, 'day')

    if (index > 0) {
      start.add(index, 'weeks').startOf('week').add(1, 'day')
    }

    let ret: number = 1 + index

    while (pointer.isBefore(start)) {
      pointer.add(1, 'weeks')
      ret++
    }

    return ret
  }

  public static batchEdit(mediaplans: any) {
    const instance_id = getModule(SystemtModule)._uuid
    const api = new Api()

    return api.post('media_plans/batch-edit', { mediaplans, instance_id })
  }

  public static feature_options = [
    {
      name: '90 seconds Ads',
      value: '90S',
    },
    {
      name: '60 seconds Ads',
      value: '60S',
    },
    {
      name: 'DV-DCM 90 seconds',
      value: 'DV90S',
    },
    {
      name: 'DV-DCM 30 seconds',
      value: '30S',
    },
    {
      name: 'DV-DCM 15 seconds',
      value: '15S',
    },
    {
      name: 'Audience Targeting',
      value: 'AT',
    },
    {
      name: '3rd Party Deal',
      value: '3PD',
    },
    // {
    //   name: 'DV-DCM',
    //   value: '3PT',
    // },
  ]

  public static tableFields: any = tableFields

  public static chartMetadata: any = {
    group: groups,
    metric: metrics,
  }

  public static async filterOptions(params: any) {
    let api = new Api()
    return api
      .get('media_plans/filter/option', params)
      .then(response => response.data)
      .catch(err => err)
  }

  public static buildQuery(payload: any) {
    let api = new Api()
    return api.post('media_plans/report', payload)
  }

  public static async bulkFind(media_plan_ids: any) {
    let api = new Api()
    return api
      .post('media_plans/search-range', { media_plan_ids })
      .then(response => {
        let mapped = response.data.result.map((item: any) => {
          const start = moment(item.start_at).format('MMMM Do, YYYY')
          const end = moment(item.end_at).format('MMMM Do, YYYY')

          item.start_at = start
          item.end_at = end

          return item
        })
        return mapped
      })
      .catch(err => err)
  }

  public searchForCreatives(ids: string[], pagination: any = {}) {
    let api = new Api()

    return api.post('media_plans/search-creatives', { ids, ...pagination }).then(response => {
      let r = response.data.result.creatives.map((item: any) => Creative.toObject(item))

      return {
        data: r,
        records: response.data.result.records,
      }
    })
  }

  public selectCreativeForPresentation(file: any, meta: any, thumb_src: any) {
    this.model_files_binary = [file]
    this.model_files_meta = [meta]
    this.export_settings.overview_creative = thumb_src
  }
}
