
import {
  Prop, Component, Watch, Ref,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import MediaPlan from '@/models/MediaPlan'
import Creative from '@/models/Creative'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import WebMessage from '@/models/WebMessage'
import SelectOption from '@/models/interface/SelectOption'
import { MediaPlanProposalActions, buildFormData } from '@/models/interface/Common'
import IconAction from '@/components/IconAction/IconAction.vue'
import ModelFileUploader from '@/components/ModelFile/ModelFileUploader.vue'
import AssociatedCreative from '@/models/AssociatedCreative'
import DataTable from '@/components/DataTable/index.vue'
import { axios } from '@/plugins/axios'
import Api from '@/models/Api'
import { uniqueId } from 'lodash'

@Component({
  components: {
    SelectPicker,
    IconAction,
    ModelFileUploader,
    DataTable,
  },
})
export default class ExportMediaPlan extends ViewModel {
  @Ref() readonly player!: HTMLFormElement

  @Ref() readonly canvas!: HTMLFormElement

  @Ref() readonly uploader!: ModelFileUploader

  @Prop({ required: true })
  public media_plans!: MediaPlan[]

  public local_media_plans: MediaPlan[] = []

  public temp_media_plan: MediaPlan = new MediaPlan()

  private showModal = true

  public has_missing_io_files: boolean = false

  public appendix: boolean = false

  public reach: boolean = false

  public io_files: string[] = ['system']

  public map_mode: string = 'dma'

  public proposal_action: string = 'New'

  public export_type: string = 'delivery_report'

  public logos: string[] = []

  public metrics: string[] = [
    'contracted_amount',
    'adserver_impressions',
    'vcr',
    'delivery_diff',
    'percentage_to_goal',
  ]

  public active_media_plan_index: number = 0

  public slides: string[] = [
    // 'cover', // On by default
    'overview',
    'details',
    'distribution',
    'placements',
    // 'outro', // On by default
  ]

  public get show_creative_fields() {
    return [
      {
        key: 'name',
        label: 'Name',
        sortable: true,
        show: true,
      },
      {
        label: false,
        key: 'action',
        type: 'action_list',
        tdClass: 'actions',
        thStyle: { width: '200px' },
        show: true,
        actions: [
          {
            icon: 'eye',
            title: 'Preview Creative',
            event: 'preview',
            top_level: true,
          },
        ],
      },
    ]
  }

  public metric_options = [
    new SelectOption('Contracted Amount', 'contracted_amount'),
    new SelectOption('Adserver Impressions', 'adserver_impressions'),
    new SelectOption('Adserver Completes', 'adserver_completes'),
    new SelectOption('VCR', 'vcr'),
    new SelectOption('Booked VCR', 'booked_vcr'),
    new SelectOption('Delivery +/-', 'delivery_diff'),
    new SelectOption('% to Goal', 'percentage_to_goal'),
    new SelectOption('Frequency', 'frequency'),
    new SelectOption('Reach', 'reach'),
  ]

  public slide_options = [
    new SelectOption('Overview', 'overview'),
    new SelectOption('Items Details', 'details'),
    new SelectOption('Distribution', 'distribution'),
    new SelectOption('Media Placement', 'placements'),
  ]

  public map_options = [new SelectOption('DMA', 'dma'), new SelectOption('State', 'state')]

  public creative_types: any = [
    { name: 'Upload', value: 'upload' },
    { name: 'From Creative', value: 'select_existing' },
  ]

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public temp_creatives: Creative[] = []

  public creative_media_file: any = null

  public loading_creative: boolean = false

  public loading_creatives: boolean = false

  public show_player_controls: boolean = false

  public creative_preview: string = 'empty'

  public creative_type: string = 'upload'

  public show_player: boolean = false

  private get selected_ids() {
    return this.local_media_plans
      .map(m => (typeof m.id === 'string' && m.id !== '-1' ? m.id : ''))
      .filter(m => m !== '')
  }

  public get io_file_options() {
    this.has_missing_io_files = false
    let ret = [{ text: 'Media Plan', value: 'system' }]

    let has_cash_file = false
    let has_trade_file = false

    this.local_media_plans.forEach((m: MediaPlan) => {
      if (m.metadata.cash_io) has_cash_file = true
      if (m.metadata.trade_io) has_trade_file = true
    })

    if (has_cash_file) {
      ret.push({ text: 'Executed IO / Agreement (Cash)', value: 'cash' })
      this.has_missing_io_files = this.local_media_plans.some((m: MediaPlan) => !m.metadata.cash_io)
    }

    if (has_trade_file) {
      ret.push({ text: 'Executed IO / Agreement (Trade)', value: 'trade' })
      if (!this.has_missing_io_files) {
        this.has_missing_io_files = this.local_media_plans.some(
          (m: MediaPlan) => !m.metadata.trade_io,
        )
      }
    }

    return ret
  }

  public get export_options() {
    this.has_missing_io_files = false

    let has_linear = this.local_media_plans.some((m: MediaPlan) => m.isLinear)
    let ret = []
    if (has_linear) {
      ret.push(new SelectOption('Proposal XML', 'xml'))
      this.has_missing_io_files = this.local_media_plans.some((m: MediaPlan) => !m.isLinear)
    }
    ret.push(
      new SelectOption('Media Plan (Excel)', 'excel'),
      new SelectOption('Insertion Order / Plan (PDF)', 'io'),
      new SelectOption('Delivery Report (PowerPoint)', 'delivery_report'),
      new SelectOption('Media Plan Snapshot (Excel)', 'snapshot'),
    )
    return ret
  }

  public get proposalOptions() {
    return MediaPlanProposalActions
  }

  public confirm() {
    if (this.export_type === 'delivery_report' && this.creative_preview !== 'empty') {
      this.deliveryReportExport().then(() =>
        WebMessage.success('Generating Files, do not close this window!'))
    } else {
      MediaPlan.export(this.selected_ids, this.export_type, {
        proposal_action: this.proposal_action,
        slides: this.slides,
        logos: this.logos,
        metrics: this.metrics,
        appendix: this.appendix,
        reach: this.reach,
        map: this.map_mode,
        io_files: this.io_files,
      }).then(() => {
        WebMessage.success('Generating Files, do not close this window!')
      })
    }
  }

  public logo_options = [
    {
      name: 'CBS Sports Network',
      value: 'CBS Sports Network',
    },
    {
      name: 'DAZN',
      value: 'DAZN',
    },
    {
      name: 'ESPN',
      value: 'ESPN',
    },
    {
      name: 'ESPN 2',
      value: 'ESPN 2',
    },
    {
      name: 'ESPN 3',
      value: 'ESPN 3',
    },
    {
      name: 'ESPN News',
      value: 'ESPN News',
    },
    {
      name: 'Eleven Sports',
      value: 'Eleven Sports',
    },
    {
      name: 'Fuel TV',
      value: 'Fuel TV',
    },
    {
      name: 'FS1',
      value: 'FS1',
    },
    {
      name: 'FS2',
      value: 'FS2',
    },
    {
      name: 'Fox Sports Network',
      value: 'Fox Sports Network',
    },
    {
      name: 'MLB',
      value: 'MLB',
    },
    {
      name: 'MLB Network',
      value: 'MLB Network',
    },
    {
      name: 'MLS (Soccer)',
      value: 'MLS (Soccer)',
    },
    {
      name: 'NBA',
      value: 'NBA',
    },
    {
      name: 'NBC Sports',
      value: 'NBC Sports',
    },
    {
      name: 'NCAA - ACC Network',
      value: 'NCAA - ACC Network',
    },
    {
      name: 'NCAA - Big 10 Network',
      value: 'NCAA - Big 10 Network',
    },
    {
      name: 'NCAA - CBS Sports Network',
      value: 'NCAA - CBS Sports Network',
    },
    {
      name: 'NCAA - Longhorn Network',
      value: 'NCAA - Longhorn Network',
    },
    {
      name: 'NCAA - Pac 12',
      value: 'NCAA - Pac 12',
    },
    {
      name: 'NCAA - SEC',
      value: 'NCAA - SEC',
    },
    {
      name: 'NFL',
      value: 'NFL',
    },
    {
      name: 'NFL Network',
      value: 'NFL Network',
    },
    {
      name: 'NHL',
      value: 'NHL',
    },
    {
      name: 'Outdoor Network',
      value: 'Outdoor Network',
    },
    {
      name: 'Outside TV',
      value: 'Outside TV',
    },
    {
      name: 'PGA - Golf Channel',
      value: 'PGA - Golf Channel',
    },
    {
      name: 'Racing America',
      value: 'Racing America',
    },
    {
      name: 'SpartanTV',
      value: 'SpartanTV',
    },
    {
      name: 'SportsGrid',
      value: 'sportsgrid',
    },
    {
      name: 'Stadium',
      value: 'Stadium',
    },
    {
      name: 'TVG',
      value: 'TVG',
    },
    {
      name: 'TVG2',
      value: 'TVG2',
    },
    {
      name: 'Tennis Channel',
      value: 'Tennis Channel',
    },

    // Legacy
    {
      name: 'BeinSports',
      value: 'BeinSports',
    },
    {
      name: 'Sportsman',
      value: 'Sportsman',
    },
    {
      name: 'ESPN U',
      value: 'ESPN U',
    },
    {
      name: 'World Fishing Network',
      value: 'World Fishing Network',
    },
  ]

  @Watch('creative_type')
  public onCreativeTypeChange(val: string) {
    this.searchForCreatives()
  }

  @Watch('creative_preview')
  public onCreativePreviewChange(val: string) {
    if (val === 'multiple') {
      this.addPresentationOverview()
    }
  }

  @Watch('page')
  public onPageChange(val: number) {
    this.searchForCreatives()
  }

  @Watch('appendix')
  public onAppendixChange(val: boolean) {
    // if true, set   { name: 'Multiple', value: 'multiple', show: true },
    if (!val) {
      if (this.creative_preview === 'multiple') {
        this.creative_preview = 'single'
      }
      this.preview_options[2].show = false
    } else {
      this.preview_options[2].show = true
    }
  }

  public preview_options: any = [
    { name: 'Empty', value: 'empty' },
    { name: 'Single Asset', value: 'single' },
    { name: 'Multiple Assets', value: 'multiple', show: false },
  ]

  public get computed_preview_options() {
    return this.preview_options.filter(o => {
      if (o.show === undefined) return true
      return o.show
    })
  }

  public searchForCreatives() {
    this.loading_creatives = true
    let ids = this.local_media_plans.map(m => m.id)

    return this.temp_media_plan
      .searchForCreatives(ids, {
        page_size: this.page_size,
        page: this.page,
        order_by: 'created_at',
        order: 'desc',
        query: [],
      })
      .then(data => {
        this.temp_creatives = data.data

        this.records = data.records

        this.loading_creatives = false
        return data
      })
      .catch(() => {
        this.loading_creatives = false
      })
  }

  public selectCreativeForPresentation(binary: any, meta: any, src: string) {
    if (this.creative_preview === 'single') {
      this.applyToAll(binary, meta, src)
    } else {
      this.local_media_plans[this.active_media_plan_index].selectCreativeForPresentation(
        binary,
        meta,
        src,
      )
    }
  }

  public async previewCreative(creative: Creative) {
    this.creative = creative
    let media_file = null
    this.loading_creative = true
    this.creative
      .preview()
      .then(async (r: any) => {
        if (r) {
          media_file = r
          await axios
            .get(r.fileURL, { responseType: 'blob' })
            .then(response => {
              media_file.media = new File([response.data], 'x.mp4', response.type)
              media_file.localUrl = window.URL.createObjectURL(media_file.media)

              this.loading_creative = false
              this.creative_media_file = media_file
              this.show_player = true
            })
            .catch(e => {
              this.loading_creative = false
              this.show_player = false
            })
        }
      })
      .catch(e => {
        this.loading_creative = false
        this.show_player = false
      })
  }

  public applyToAll(file, meta, src) {
    let copy = this.local_media_plans
    copy.forEach((_, index: number) => {
      copy[index].selectCreativeForPresentation(file, meta, src)
    })

    this.local_media_plans = copy
  }

  public captureVideoFrame(e: any) {
    const name = `${'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0
      const v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })}.png`

    e.preventDefault()
    this.player.pause()
    this.canvas.width = this.player.videoWidth
    this.canvas.height = this.player.videoHeight
    this.canvas
      .getContext('2d')
      .drawImage(this.player, 0, 0, this.player.videoWidth, this.player.videoHeight)

    this.canvas.toBlob(blob => {
      // FileSaver.saveAs(blob, `${this.creative.name}.preview.png`)

      const reader = new FileReader()

      let file = new File([blob], name)

      let match = file.name.match(/\.([0-9a-z]+$)/i)

      let ext = ''
      if (match) {
        ext = match[1]
      }

      reader.onloadend = () => {
        let base = {
          file,
          meta: {
            name: file.name.replace(/\.[0-9a-z]+$/, ''),
            source_name: file.name,
            description: '',
            size: file.size,
            type: ext,
          },
          src: reader.result,
        }

        this.selectCreativeForPresentation(base.file, base.meta, base.src)
      }

      reader.readAsDataURL(file)
    })
  }

  public mounted() {
    this.local_media_plans = JSON.parse(JSON.stringify(this.media_plans))

    this.local_media_plans = this.local_media_plans.map(m => MediaPlan.toObject(m))

    let mp = new MediaPlan()
    mp.id = '-1'
    mp.name = 'Order Overview'
    this.local_media_plans.unshift(mp)
  }

  public deliveryReportExport() {
    let media_plans = this.local_media_plans.map(m => ({
      id: m.id,
      name: m.name,
      binary: m.model_files_binary,
      meta: m.model_files_meta,
    }))

    let uniques: any = {
      metas: [],
      binaries: [],
    }
    let mp = {}

    for (let index = 0; index < media_plans.length; index++) {
      const media_plan = media_plans[index]

      if (!media_plan.meta.length) {
        // eslint-disable-next-line no-continue
        continue
      }

      let exists = uniques.metas.findIndex(m => m.source_name === media_plan.meta[0].source_name)

      if (exists === -1) {
        uniques.metas.push(media_plan.meta[0])
        uniques.binaries.push(media_plan.binary[0])
      }

      mp[media_plan.id] = media_plan.meta[0].source_name
    }

    let export_params: any = {
      media_plans: this.local_media_plans.map(m => m.id),
      model_files_meta: uniques.metas,
      model_files_binary: uniques.binaries,
      proposal_action: this.proposal_action,
      slides: this.slides,
      logos: this.logos,
      metrics: this.metrics,
      appendix: this.appendix,
      reach: this.reach,
      map: this.map_mode,
      io_files: this.io_files,
      attach_to_presentation: mp,
      preview_type: this.creative_preview,
      creative_type: this.creative_type,
    }

    const formData = new FormData()

    buildFormData(formData, {
      ...export_params,
      instance_id: this.instance_id,
    })

    const api = new Api()

    return api.post(`media_plans/export/${this.export_type}`, formData)
  }
}
